import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@core/services/http/base-http.service';
import { Page } from '@core/data/model/page';
import { Contributor } from '@core/data/model/contributor';
import { ContributorFilter } from '@core/data/model/dto/contributor-filter';
import { List } from 'postcss/lib/list';
import { MergeContributor } from '@core/data/model/dto/merge-contributor';
import { tap } from 'rxjs/operators';

@Injectable()
export class ContributorService extends BaseHttpService {

  constructor(protected override httpClient: HttpClient) {
    super('contributor-service', httpClient);
  }

  searchContributors(
    organizationPlatformResourceIds?: string[],
    searchPhrase?: string,
    page = 0,
    size = 30,
    sortKey = 'name',
    sortDir = 'asc',
    joinChildren = false,
    joinPossibleMatches = false,
  ): Observable<Page<Contributor>> {
    const filter: ContributorFilter = {
      searchPhrase: searchPhrase,
      organizationPlatformResourceIds: organizationPlatformResourceIds,
      joinChildren: joinChildren,
      joinPossibleMatches: joinPossibleMatches
    };
    return this.post<Page<Contributor>>(`/contributors/search?page=${page}&size=${size}&sort=${sortKey},${sortDir}`, filter).pipe(
      tap(result => {
        result.content = result.content.map(dev => Object.assign(new Contributor(), dev))
      })
    );
  }

  mergeContributors(contributor: Contributor, duplicateContributors: Contributor[]): Observable<void> {
    const mergeContributor: MergeContributor = {
      duplicateContributorIds: duplicateContributors.map(dev => dev.id)
    };
    return this.post<void>(`/contributors/${contributor.id}/merge`, mergeContributor);
  }
}
