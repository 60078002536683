import { AbstractAuditedModel } from './abstract-audited-model';
import { PlatformType } from '@core/data/type/platform.type';
import { PossibleContributorMatch } from '@core/data/model/possible-contributor-match';
import { ContributorCostRate } from '@core/data/model/contributor-cost-rate';

export class Contributor extends AbstractAuditedModel {
  // transient
  _checked = false;
  _possibleMatch = false;
  _notMergedPossibleMatches: PossibleContributorMatch[] = [];

  constructor(public name?: string,
              public username?: string,
              public email?: string,
              public url?: string,
              public avatarUrl?: string,
              public platformType?: PlatformType,
              public externalId?: string,
              public timeZoneOffset?: number, // minutes
              public parent?: Contributor,
              public initials?: string,
              public children?: Contributor[],
              public possibleMatches?: PossibleContributorMatch[],
              public contributorCostRate?: ContributorCostRate,
              public organizationId?: string) {
    super();
  }

  /**
   * This method basically returns the parent contributor if it was set since this is a merged contributor if its parent is set
   */
  get root(): Contributor {
    return this.parent ? this.parent : this;
  }
}
